:root {
    --padding: 1%;
    --margin: 1%;
    --vertical-padding: 1em;
    --vertical-margin: 1em;
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    --main-color-background: #121212;
    --main-color: #fff;
    --accent-color-background: #121212;
    --accent-color: #fff;
    --header-fontweight: 600;
}

@import "/theme/intendit/css/modules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    
@import "/theme/intendit/css/modules/basic_styling.css";

@import "/theme/intendit/css/modules/flex_grid.css";

@import "/theme/intendit/css/modules/slick.css";

@import "/theme/intendit/css/modules/menu.css";

@import "/theme/intendit/css/modules/font-awesome.4.5.0.css";

@import "/theme/intendit/css/modules/magnific-popup.css";
            
/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

* {
    letter-spacing: 0 !important;
}

html {
    height: 100%;
    font-size: 16px;
    line-height: 24px;
}

body {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: var(--header-fontweight);
}

h2 {
    font-size: 2.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: var(--header-fontweight);
}

h2.boxheader {
    color: var(--main-color);
    background: var(--main-color-background);
    margin: 0;
    padding: 1rem;
    font-weight: var(--header-fontweight);
}

h3 {
    font-size: 1.625rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: var(--header-fontweight);
}

.button {
    margin-left: 0;
    margin-right: 0;
}

.imgwrapper {
    margin-bottom: 1rem;
}
/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

header {
    background: #fff;
    color: #121212;
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
    border-bottom: 9px solid var(--accent-color-background);
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--accent-color-background);
    color: #fff;
}

header a {
    text-decoration: none;
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin: 0;
}

.slick-track {
    height: 45vh;
}

.slick-track > div {
    background-size: cover;
}

/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}

header, footer {
    flex: none;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    outline: 0 !important;
}



/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

/* Example media queries*/
@media (max-width: var(--breakpoint-s)) {

    h1 { font-size:2rem; }
    h2 { font-size: 1.625rem; }
}
